//@author: mandy

/* TODO once data available and this comp finalized, let put CSS in main css file  */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, NgZone, OnInit } from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-charts")
@Component({
    selector: "s25-ng-charts",
    template: "<div *ngIf='init'>Hello World</div>",
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25ChartsComponent implements OnInit {
    init: boolean;

    constructor(
        private elementRef: ElementRef,
        private zone: NgZone,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit() {
        this.init = true;
    }
}

// @TypeManagerDecorator("s25-ng-charts")
// @Component({
//     selector: "s25-ng-charts",
//     template: `
//         <div *ngIf="this.init" id="charts" class="c-margin-left--half c-margin-top--half  c-margin-bottom--half ">
//             <div *ngIf="this.data.length === 0">No Data Available</div>
//
//             <div *ngIf="this.data.length > 0">
//                 <svg id="svgSec" height="30%" width="30%" viewBox="0 0 20 20" class="c-margin-right--half"></svg>
//
//                 <div class="ngFinePrint c-margin-left--half legend">
//                     <div class=" c-margin-top--half" *ngFor="let i of this.data; let idx = index">
//                         <div (mouseover)="mouseOver(idx)" (mouseleave)="mouseOut(idx)">
//                             <span class="{{ i.class }}"> </span> {{ i.name }} {{ i.value }}%
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     `,
//     styles: [
//         "#charts .legend { margin-right: 100px !important; float: right; }",
//         ".legend span { border: 1px solid #ccc; float: left; width: 12px; height: 12px; margin: 2px; }",
//         /* legend colors */
//         ".very-satified { background-color: #0077D7; }",
//         ".satified { background-color: #80BBEB; }",
//         ".neutral { background-color: #005499; }",
//         ".disatified { background-color: #268BDD; }",
//         ".very-disatified { background-color: #002A4C; }",
//         ".drop-shadow { -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7)); filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));}",
//     ],
//
//     encapsulation: ViewEncapsulation.None,
//     changeDetection: ChangeDetectionStrategy.Default,
// })
// export class S25ChartsComponent implements OnInit, AfterViewInit {
//     @Input() itemId: number;
//     @Input() data?: any[] = [];
//     init: boolean = false;
//
//     colorScheme: any[] = ["#0077D7", "#80BBEB", "#005499", "#268BDD", "#002A4C"]; //not in use --- #3273a0 #80BBEB
//     pieChartLabels: any[] = [80, 10, 3, 2, 5]; // not in use
//     elem: any;
//
//     constructor(private elementRef: ElementRef, private zone: NgZone, private cd: ChangeDetectorRef) {
//         this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
//     }
//
//     ngAfterViewInit() {
//         setTimeout(() => {
//             if (document.getElementById("svgSec")) {
//                 document.getElementById("svgSec").innerHTML = this.elem;
//             }
//         }, 60);
//     }
//
//     mouseOver(i: number) {
//         const id: any = "circle" + i;
//         let elem: any = document.getElementById(id);
//         //elem.setAttribute("style", 'filter: drop-shadow(' + this.data[i].x  + this.data[i].x + '5px #eee)');
//         // elem.setAttribute("stroke", "#008751");
//         //elem.setAttribute("class", "drop-shadow");
//     }
//
//     mouseOut(i: number) {
//         const id: any = "circle" + i;
//         let elem: any = document.getElementById(id);
//         // elem.setAttribute("r", "5");
//         elem.setAttribute("stroke", this.data[i].color);
//     }
//
//     ngOnInit() {
//         this.zone.run(() => {
//             setTimeout(() => {
//                 let lastend = 0;
//                 let total = 100;
//
//                 /* TODO once data available, need to values this  */
//                 this.data = [
//                     { name: "Very Satified", value: 80, color: "#0077D7", class: "very-satified" }, //
//                     { name: "Satified", value: 10, color: "#80BBEB", class: "satified" },
//                     { name: "Neutral", value: 3, color: "#005499", class: "neutral" },
//                     { name: "Disatified", value: 2, color: "#268BDD", class: "disatified" },
//                     { name: "Very Disatified", value: 5, color: "#002A4C", class: "very-disatified" },
//                 ];
//                 /* END Data settin  */
//
//                 if (this.data && this.data.length > 0) {
//                     for (let i = 0; i < this.data.length; i++) {
//                         this.data[i].value = parseFloat(this.data[i].value);
//                         this.data[i].dasharray = ((this.data[i].value * 31.4) / 100).toFixed(3); // float to 2 decimals, 10% 3.14
//
//                         switch (i) {
//                             case 0:
//                                 this.data[i].rotate = "0";
//                                 this.data[i].offset = 0;
//                                 this.data[i].dashoffset = 0;
//                                 this.data[i].circle =
//                                     '<circle id="circle0" r="5" cx="10" cy="10"  fill="#0077D7" stroke="' +
//                                     this.data[i].color +
//                                     '"   stroke-width="10" stroke-dasharray="' +
//                                     this.data[i].dasharray +
//                                     ' 31.4" > ' +
//                                     "<title>" +
//                                     this.data[i].value +
//                                     "% " +
//                                     this.data[i].name +
//                                     "</title>" +
//                                     "</circle>";
//                                 break;
//
//                             case 1:
//                                 this.data[i].offset = this.data[0].value;
//                                 break;
//
//                             case 2:
//                                 this.data[i].offset = this.data[0].value + this.data[1].value;
//                                 break;
//                             case 3:
//                                 this.data[i].offset = this.data[0].value + this.data[1].value + this.data[2].value;
//                                 break;
//                             case 4:
//                                 this.data[i].offset =
//                                     this.data[0].value + this.data[1].value + this.data[2].value + this.data[3].value;
//                                 break;
//                         }
//
//                         this.data[i].degress = (this.data[i].offset / 100) * 360; //degress( 0.8*360 )
//                         this.data[i].dashoffset = -((this.data[i].offset * 31.4) / 100).toFixed(3);
//                         this.data[i].angle = Math.PI * 2 * (this.data[i].value / total);
//                         this.data[i].rotate = lastend + this.data[i].angle / 2;
//                         lastend += this.data[i].angle;
//
//                         if (i === 2 || i === 4) {
//                             this.data[i].x = (10 + 7 * Math.cos(this.data[i].rotate)).toFixed(3);
//                             this.data[i].y = (10 + 7 * Math.sin(this.data[i].rotate)).toFixed(3);
//                         } else {
//                             this.data[i].x = (10 + 6 * Math.cos(this.data[i].rotate)).toFixed(3);
//                             this.data[i].y = (10 + 6 * Math.sin(this.data[i].rotate)).toFixed(3);
//                         }
//
//                         this.data[i].circle =
//                             '<circle id="circle' +
//                             i +
//                             '" r="5" cx="10" cy="10" fill="transparent" stroke="' +
//                             this.data[i].color +
//                             '"  stroke-width="10" stroke-dasharray="' +
//                             this.data[i].dasharray +
//                             ' 31.4" ' +
//                             'stroke-dashoffset="' +
//                             this.data[i].dashoffset +
//                             '"' +
//                             "> " +
//                             "<title>" +
//                             this.data[i].value +
//                             "% " +
//                             this.data[i].name +
//                             "</title>" +
//                             "</circle>";
//
//                         this.data[i].text =
//                             '<text text-anchor= "middle" dominant-baseline="hanging" x="' +
//                             this.data[i].x +
//                             '" y="' +
//                             this.data[i].y +
//                             '" font-size="1px" fill="white">' +
//                             this.data[i].value +
//                             "%</text>";
//                     }
//                     //SVG circle can't do binding via dynamic values, so create html here
//                     this.elem =
//                         '<circle r="5" cx="10" cy="10" fill="white"></circle>' +
//                         this.data[0].circle +
//                         this.data[1].circle +
//                         this.data[2].circle +
//                         this.data[3].circle +
//                         this.data[4].circle +
//                         this.data[0].text +
//                         this.data[1].text +
//                         this.data[2].text +
//                         this.data[3].text +
//                         this.data[4].text;
//                 }
//                 this.init = true;
//                 // console.log({ data: this.data});
//                 this.cd.detectChanges();
//             }, 60);
//         });
//     }
// }
