import { ValueOf } from "../../pojo/Util";
import { S25Util } from "../../util/s25-util";

function putFormat(attribute: CustomAttribute.Attribute, value: string | number, s25BoolValue: boolean) {
    switch (attribute.itemTypeId) {
        case CustomAttribute.type.Boolean:
            // Map boolean values to 1/0 for true/false bc PUT expects 1/0 format (or T/F if s25BoolValue is true)
            if (S25Util.isUndefined(value)) {
                return undefined;
            } else {
                return S25Util.toBool(value) ? (s25BoolValue ? "T" : "1") : s25BoolValue ? "F" : "0";
            }
        case CustomAttribute.type.Datetime:
        case CustomAttribute.type.Duration:
            // Map datetime, duration Date() values to an ISO 8601 datetime string
            return S25Util.date.toS25ISODateTimeStr(value, false); // Strip seconds
        case CustomAttribute.type.Date:
            // Map date values to an ISO 8601 date string (no time)
            return S25Util.date.toS25ISODateStr(value);
        case CustomAttribute.type.Time:
            // Map time values to an ISO 8601 datetime string with date portion 1900-01-01 (PUT expects this)
            return value && "1900-01-01T" + S25Util.date.toS25ISOTimeStr(value); // Seconds are stripped for us by toS25ISOTimeStr
        case CustomAttribute.type.Organization:
        case CustomAttribute.type.Contact:
        case CustomAttribute.type.Location:
        case CustomAttribute.type.Resource:
            return attribute.itemId;
        default:
            return value;
    }
}

function getFormat(attribute: CustomAttribute.Attribute, value: string) {
    switch (attribute.itemTypeId) {
        case CustomAttribute.type.Float:
            if (attribute.standardInput) return value;
            // For display, parse float to remove trailing zeroes after the decimal
            // noaction = bpe scenario form where we use numeric input for floats
            return S25Util.isFloat(value) ? "" + S25Util.toFloat(value) : null;
        case CustomAttribute.type.Datetime:
        case CustomAttribute.type.Date:
        case CustomAttribute.type.Duration:
            // For datetime/date display, drop any timezone info
            return value && S25Util.date.dropTZString(value);
        case CustomAttribute.type.Time:
            // Time comes to us as a HH:MM string, append it to 1970-01-01 date so timepickers can work with a datetime value
            if (value.includes("T")) {
                // If ISO-8601 date string, get just time info
                value = value.substring(value.indexOf("T") + 1);
            }
            return value && S25Util.date.toS25ISODateTimeStr("1970-01-01T" + value);
        case CustomAttribute.type.Boolean:
            // Map 0/1 booleans to real booleans for toggle and also easy reverting on error since toggle expects bools
            return S25Util.isUndefined(value) ? undefined : value === "F" ? false : S25Util.toBool(value);
        case CustomAttribute.type.String:
            return value?.toString();
        default:
            return value;
    }
}

export const S25CustomAttributeItemFormatter = {
    putFormat,
    getFormat,
};

export namespace CustomAttribute {
    export type Type = ValueOf<typeof type>;
    export const type = {
        Boolean: "B",
        Image: "I",
        LargeText: "X",
        Organization: 2,
        Contact: 3,
        Location: 4,
        Resource: 6,
        FileReference: "R",
        String: "S",
        Date: "D",
        Datetime: "E",
        Time: "T",
        Integer: "N",
        Float: "F",
        Duration: "L",
    } as const;

    export type Attribute = {
        itemTypeId: Type;
        standardInput?: boolean;
        itemId?: number | string;
    };
}
