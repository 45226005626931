import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { ProfileApiI, ProfileI } from "../../pojo/ProfileI";
import { TimeModelI } from "../../pojo/TimeModelI";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { S25DatePatternPickerComponent } from "../s25-datepattern/s25.datepattern.picker.component";
import { Proto } from "../../pojo/Proto";
import { Event } from "../../pojo/Event";
import ISODateString = Proto.ISODateString;
import Occurrence = Event.Occurrence;

@TypeManagerDecorator("s25-ng-constraints")
@Component({
    selector: "s25-ng-constraints",
    template: `
        <div *ngIf="init">
            <label class="ngBold" name="profileName"
                >Name
                <input
                    class="c-input c-margin-right--half"
                    id="profileName"
                    name="profileName"
                    [value]="profileModel.profile_name"
                    [(ngModel)]="profileModel.profile_name"
                    (change)="onChange()"
                    type="text"
                    maxlength="80"
                />
            </label>

            <label name="constraintLevel ngBold"
                >Type
                <select
                    class="cn-form__control c-margin-top--quarter c-margin-left--half"
                    [(ngModel)]="profileModel.prof_use"
                    (change)="profUseChange()"
                    name="constraintLevel"
                >
                    <option
                        *ngFor="let p of this.profUse"
                        [ngValue]="p.itemTypeId"
                        [selected]="p.prof_use === p.itemTypeId"
                    >
                        {{ p.itemName }}
                    </option>
                </select>
            </label>
            <div class="c-margin-top--single">
                <s25-ng-editable-start-end-datetime
                    [startDatetime]="profileModel.dates.evStartDt"
                    (startDatetimeChange)="onStartChange($event, 'start')"
                    [endDatetime]="profileModel.dates.evEndDt"
                    (endDatetimeChange)="onStartChange($event, 'end')"
                    [startDateUpdateValidate]="validateDate"
                    [endDateUpdateValidate]="validateDate"
                    [timeUpdateValidate]="validateTime"
                    [minDate]="minDate"
                    [maxDate]="maxDate"
                    [alwaysEditing]="true"
                >
                </s25-ng-editable-start-end-datetime>
                <s25-ng-date-pattern-picker
                    [timeModel]="timeModel"
                    [profileCode]="profileModel.profile_code"
                    [adHocOccs]="adHocOccs"
                    [(apiBean)]="datePatternApi"
                    (apiBeanChange)="profileChange($event)"
                    [minDate]="minDate"
                    [maxDate]="maxDate"
                    [includeFirstDate]="true"
                >
                </s25-ng-date-pattern-picker>
            </div>
        </div>
    `,
    // styles:["s25-ng-editable-start-end-datetime: {margin-top: 15px;}""]
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25ConstraintsComponent implements OnInit {
    @Input() profileModel: ProfileI;
    @Input() validateConstraint?: (obj: Date) => boolean | string;
    @Input() minDate?: Date;
    @Input() maxDate?: Date;
    @Output() profileModelChange = new EventEmitter<ProfileI>();

    @ViewChild(S25DatePatternPickerComponent) patternPickerComponent: S25DatePatternPickerComponent;

    init = false;
    profUse = [
        { itemTypeId: -1, itemName: "Exclude" },
        { itemTypeId: -2, itemName: "Warning" },
    ];
    datePatternApi: any = {};
    adHocOccs: Occurrence[] = [];
    timeModel: TimeModelI;

    static constraintCount: number = 0;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        S25ConstraintsComponent.constraintCount++;
    }

    ngOnInit(): void {
        this.profileModel = this.profileModel || {};
        this.profileModel.profile_code = this.profileModel.profile_code || "dnr";
        this.adHocOccs = S25Util.array
            .forceArray(this.profileModel.occurrences as any as ISODateString)
            .map((occ: ISODateString) => ({ evStartDt: S25Util.date.parse(occ), evEndDt: S25Util.date.parse(occ) }));
        this.profileModel.origOccurrences = S25Util.deepCopy(this.profileModel.occurrences);
        this.profileModel.dates = {
            evStartDt:
                (this.profileModel.dates && this.profileModel.dates.evStartDt) ||
                this.profileModel.init_start_dt ||
                S25Util.date.currentDate(),
            evEndDt:
                (this.profileModel.dates && this.profileModel.dates.evEndDt) ||
                this.profileModel.init_end_dt ||
                S25Util.date.toEndOfDay(S25Util.date.currentDate()),
            minutes: (this.profileModel.dates && this.profileModel.dates.minutes) || {},
        };
        this.timeModel = {
            ...this.profileModel.dates,
            evEndDt: S25Util.date.copyTime(
                new Date(this.profileModel.dates.evStartDt),
                this.profileModel.dates.evEndDt,
            ),
        };
        this.setLimits();
        this.init = true;
    }

    setLimits() {
        // this.minDate = this.minDate || S25Util.date.addYears(S25Util.date.currentDate(), -10);
        // this.maxDate = this.maxDate || S25Util.date.addYears(S25Util.date.currentDate(), 10);
    }

    onChange() {
        this.profileModelChange.emit(this.profileModel);
    }

    profUseChange() {
        let profUse = S25Util.array.getByProp(this.profUse, "itemTypeId", this.profileModel.prof_use);
        this.profileModel.prof_use_name = profUse && profUse.itemName;
        this.onChange();
    }

    onStartChange(date: any, type: string) {
        type === "start" ? (this.timeModel.evStartDt = date) : (this.timeModel.evEndDt = date);
        let profile = this.patternPickerComponent.getProfileModel();
        if (profile.type === "adhoc") {
            this.patternPickerComponent.changeFirstDt(date);
        }
        this.setLimits();
        this.onChange();
    }

    profileChange(apiBean: ProfileApiI) {
        let occ = this.datePatternApi.getOccurrences() || [];
        S25Util.extend(this.profileModel, {
            occurrences: occ.map((o: any) => {
                return o.evStartDt || o;
            }),
            profile_code: this.datePatternApi.getProfileCode(),
            profileModel: this.datePatternApi.getProfileModel(),
        });

        this.onChange();
    }

    validateDate = (obj: any) => {
        //type Date or {date: date, type: 'start' | 'end'}
        return this.validateConstraint && obj
            ? this.validateConstraint(S25Util.date.isDate(obj) ? obj : obj.date)
            : true;
    };

    validateTime(obj: any) {
        //Not much additional time validation to do handle
        return true;
    }
}
