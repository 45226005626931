import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from "@angular/core";
import { ContextMenuService } from "./context.menu.service";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-context-menu")
@Component({
    selector: "s25-ng-context-menu",
    template: `<div *ngIf="init">
        <div class="ngContextMenu b-contextmenu" id="S25Object_ContextMenu_{{ objectMap[data.itemTypeId] }}">
            <table>
                <tbody>
                    <tr id="S25Object_ContextMenu_{{ objectMap[data.itemTypeId] }}_Header" class="b-contextmenurow">
                        <td class="ngContextMenuRow b-contextmenu-label-expand">
                            {{ lang.header }}
                        </td>
                    </tr>

                    <ng-container *ngIf="data.itemTypeId === 19991">
                        <tr class="b-contextmenurow">
                            <td
                                class="c-contextmenu-label-expand ngCpointer"
                                (click)="modelBean.data.deleteRsrv()"
                                (keydown.enter)="modelBean.data.deleteRsrv()"
                                [attr.disabled]="!modelBean.data.perms.canDelete"
                                tabindex="0"
                            >
                                Occurrence Delete
                            </td>
                        </tr>
                        <tr class="b-contextmenurow">
                            <td
                                class="b-contextmenu-label-expand ngCpointer"
                                (click)="modelBean.data.copyRsrv()"
                                (keydown.enter)="modelBean.data.copyRsrv()"
                                [attr.disabled]="!modelBean.data.perms.canCopy"
                                tabindex="0"
                            >
                                Occurrence Copy
                            </td>
                        </tr>
                        <tr class="b-contextmenurow">
                            <td
                                class="b-contextmenu-label-expand ngCpointer"
                                (click)="modelBean.data.rsrvDetails()"
                                (keydown.enter)="modelBean.data.rsrvDetails()"
                                tabindex="0"
                            >
                                Occurrence Copy
                            </td>
                        </tr>
                    </ng-container>

                    <tr *ngIf="modelBean.data.subPerms.hasOpenInNewTab" class="b-contextmenurow">
                        <td class="b-contextmenu-label-expand ngCpointer">
                            <a
                                class="ngUnstyledAnchor ngWidth100 ngInlineBlock"
                                href="{{ modelBean.data.itemUrl }}"
                                target="_blank"
                                rel="noopener"
                                >Open in New Tab</a
                            >
                        </td>
                    </tr>
                    <tr class="b-contextmenurow">
                        <td
                            class="b-contextmenu-label-expand ngCpointer"
                            (click)="modelBean.data.details()"
                            (keydown.enter)="modelBean.data.details()"
                            tabindex="0"
                        >
                            {{ lang.details }}
                        </td>
                    </tr>

                    <ng-container *ngIf="data.itemTypeId === 1">
                        <tr class="b-contextmenurow">
                            <td
                                class="b-contextmenu-label-expand ngCpointer"
                                (click)="modelBean.data.calendar()"
                                (keydown.enter)="modelBean.data.calendar()"
                                tabindex="0"
                            >
                                {{ lang.calendar }}
                            </td>
                        </tr>
                        <tr
                            *ngIf="modelBean.data.subPerms.hasEditEvent"
                            id="S25Object_ContextMenuRow_EventEdit"
                            class="b-contextmenurow"
                        >
                            <td
                                class="b-contextmenu-label-expand ngCpointer"
                                (click)="modelBean.data.editEvent()"
                                (keydown.enter)="modelBean.data.editEvent()"
                                tabindex="0"
                            >
                                {{ lang.edit_event }}
                            </td>
                        </tr>
                        <tr
                            *ngIf="modelBean.data.subPerms.hasCopyEvent"
                            id="S25Object_ContextMenuRow_EventClone"
                            class="b-contextmenurow"
                        >
                            <td
                                class="b-contextmenu-label-expand ngCpointer"
                                (click)="modelBean.data.copyEvent()"
                                (keydown.enter)="modelBean.data.copyEvent()"
                                tabindex="0"
                            >
                                {{ lang.copy_event }}
                            </td>
                        </tr>
                        <tr
                            *ngIf="modelBean.data.subPerms.hasEmail"
                            id="S25Object_ContextMenuRow_EmailEventDetails"
                            class="b-contextmenurow"
                        >
                            <td
                                class="b-contextmenu-label-expand ngCpointer"
                                (click)="modelBean.data.emailEvent()"
                                (keydown.enter)="modelBean.data.emailEvent()"
                                tabindex="0"
                            >
                                {{ lang.email_event }}
                            </td>
                        </tr>
                        <tr
                            *ngIf="modelBean.data.subPerms.hasSocOption"
                            id="S25Object_ContextMenu_Event_Header_Actions"
                            class="b-contextmenurow"
                        >
                            <td class="b-contextmenu-label-expand ngContextMenuRow">
                                <b>Actions...</b>
                            </td>
                        </tr>
                        <tr
                            *ngIf="modelBean.data.subPerms.hasSocOption"
                            id="S25Object_ContextMenuRow_StandOutClassroom"
                            class="b-contextmenurow"
                        >
                            <td
                                class="b-contextmenu-label-expand ngCpointer"
                                (click)="modelBean.data.setStandOut()"
                                (keydown.enter)="modelBean.data.setStandOut()"
                                tabindex="0"
                            >
                                Add StandOut Classroom
                            </td>
                        </tr>
                    </ng-container>

                    <ng-container *ngIf="data.itemTypeId !== 1">
                        <tr class="b-contextmenurow">
                            <td
                                class="b-contextmenu-label-expand ngCpointer"
                                (click)="modelBean.data.list()"
                                (keydown.enter)="modelBean.data.list()"
                                tabindex="0"
                            >
                                {{ lang.list }}
                            </td>
                        </tr>

                        <ng-container *ngIf="data.itemTypeId === 4 || data.itemTypeId === 6">
                            <tr class="b-contextmenurow">
                                <td
                                    class="b-contextmenu-label-expand ngCpointer"
                                    (click)="modelBean.data.availability()"
                                    (keydown.enter)="modelBean.data.availability()"
                                    tabindex="0"
                                >
                                    {{ lang.availability }}
                                </td>
                            </tr>
                        </ng-container>

                        <tr class="b-contextmenurow">
                            <td
                                class="b-contextmenu-label-expand ngCpointer"
                                (click)="modelBean.data.calendar()"
                                (keydown.enter)="modelBean.data.calendar()"
                                tabindex="0"
                            >
                                {{ lang.calendar }}
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>`,
    styles: `
        :host ::ng-deep .b-contextmenu {
            top: unset;
            left: unset;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25ContextMenuComponent implements OnInit, OnDestroy {
    data: { itemTypeId: 1 | 2 | 3 | 4 | 6 | 19991; itemId: number; bean: any };
    modelBean: any;
    init: boolean;
    lang: any;
    objectMap: { [key: number]: "Event" | "Space" | "Resource" | "Organization" | "Contact" } = {
        1: "Event",
        2: "Organization",
        3: "Contact",
        4: "Space",
        6: "Resource",
        19991: "Event",
    };

    constructor(
        private cd: ChangeDetectorRef,
        private elementRef: ElementRef,
    ) {}

    async ngOnInit() {
        const { itemTypeId, itemId, bean } = this.data;
        this.modelBean = await ContextMenuService.hydrateMenu(itemTypeId, itemId, bean);

        this.lang = this.modelBean.data.lang.div.controls.application.text.contextmenu;
        this.init = true;
        this.cd.detectChanges();
    }

    ngOnDestroy() {
        this.elementRef.nativeElement.remove();
    }
}
