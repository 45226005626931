import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { S25DropdownPaginatedModule } from "../s25-dropdown/s25.dropdown.paginated.module";
import { FormsModule } from "@angular/forms";
import { S25EditableBooleanModule } from "../s25-editable/s25-editable-boolean/s25.editable.boolean.module";
import { S25EditableModule } from "../s25-editable/s25.editable.module";
import { S25TimepickerModule } from "../s25-timepicker/s25.timepicker.module";
import { S25MultiselectModule } from "../s25-multiselect/s25-multiselect.module";
import { S25CustomAttributeValueComponent } from "./s25.custom.attribute.value.component";
import { S25ToggleButtonModule } from "../s25-toggle-button/s25.toggle.button.module";

@NgModule({
    imports: [
        CommonModule,
        S25DropdownPaginatedModule,
        FormsModule,
        S25EditableBooleanModule,
        S25EditableModule,
        S25TimepickerModule,
        S25MultiselectModule,
        S25ToggleButtonModule,
    ],
    exports: [S25CustomAttributeValueComponent],
    declarations: [S25CustomAttributeValueComponent],
    providers: [S25CustomAttributeValueComponent],
})
export class S25CustomAttributeModule {}
